import api from "../axiosInstance";
import { getUserRole, logout } from "../../utils/authUtils";

async function updateLiveStatus(annonceId) {
    try {
        console.log(annonceId);
        const role = getUserRole();
        let url = '';

        // Génération de l'URL en fonction du rôle de l'utilisateur
        if (role === 'super-admin' || role === 'all_pays') {
            url = `/annonce/update-live-status/${annonceId}`;
        } else {
            url = `/annonce/update-live-status/${role}/${annonceId}`;
        }

        // Appel à l'API sans envoyer de statut, car il est géré dans la méthode côté serveur
        const response = await api.put(url);
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Déconnexion si l'utilisateur n'est pas autorisé
            logout();
        }
        console.log(error);

        // Gestion des erreurs et extraction des messages d'erreur spécifiques
        if (error.response && error.response.data && error.response.data.errors) {
            const { errors } = error.response.data;
            return { errors };
        } else {
            console.error('error:', error.message);
            throw error;
        }
    }
}

export { updateLiveStatus };
