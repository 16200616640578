import React, { useState } from "react"; 
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "../ui/Input";
import { addAnnonce } from "../../../Service/Annonce/AnnonceAdd";
import ApiSuccess from "../../Error-succes/Api-succes";
import { getCountryId } from "../../../utils/authUtils";

const validationSchema = yup.object({
    presentation: yup.string().min(10, 'La présentation doit contenir au moins 10 caractères').required('La présentation est obligatoire'),
    image: yup.string().url('Doit être une URL valide').required('L\'URL de l\'image est obligatoire'),
    date: yup.date().required('La date est obligatoire'),
    categorie: yup.string().required('La catégorie est obligatoire'),
    title: yup.string().min(2, 'Le titre doit contenir au moins 2 caractères').required('Le titre est obligatoire'),
    user_id: yup.number().positive('L\'ID employé doit être un nombre positif').required('L\'ID employé est obligatoire'), // Remplacement ici
    lives: yup.boolean().required('Le champ Live est obligatoire'),
    date_end: yup.date().nullable().optional(),
    status: yup.number().positive('Le statut doit être un nombre positif').required('Le statut est obligatoire'),
});

const AddAnnonce = () => {
    const [addAnnonceErrors, setAddAnnonceErrors] = useState([]);
    const [registrationSuccess, setRegistrationSuccess] = useState("");

    const methods = useForm({
        resolver: yupResolver(validationSchema),
    });
    const { register, handleSubmit, formState: { errors }, setValue, getValues } = methods;

    const employees = [
        { id: 1, name: "Clients" },
        { id: 2, name: "Publisher" },
        { id: 3, name: "Sales" },
        { id: 4, name: "Tradedoubler France" },
        { id: 5, name: "Publisher" },
        { id: 6, name: "Metapic" },
    ];

    const handleSaveClick = async () => {
        try {
            const data = getValues();
            data.status = 1;

            let country_id = getCountryId();
            if (country_id) {
                country_id = Number(country_id);
                if (isNaN(country_id)) {
                    console.error("Invalid country_id:", country_id);
                    return;
                }
                data.country_id = country_id;
            } else {
                console.error("No country_id found in localStorage");
                return;
            }

            const response = await addAnnonce(data);
            if (response && response.errors) {
                setAddAnnonceErrors(response.errors);
            }
            if (response && !response.errors) {
                setRegistrationSuccess("Annonce ajoutée avec succès!");
                setAddAnnonceErrors([]);
                window.location.reload();
            }
        } catch (error) {
            console.error('Error adding annonce:', error);
        }
    };

    const categorie = [
        { value: "general", label: "general" },
        { value: "social", label: "social" },
        { value: "media", label: "media" },
        { value: "departure", label: "departure" },
    ];

    return (
        <div>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(handleSaveClick)}>
                    {registrationSuccess && <ApiSuccess message={registrationSuccess} />}
                    {addAnnonceErrors.map((error, index) => (
                        <div className={"text-red-500"} key={index}>{error.message}</div>
                    ))}

                    <Input type="textarea" {...register("presentation")} placeholder="Presentation" color="blue" size="lg" label="Presentation" />
                    <p>{errors.presentation?.message}</p>

                    <Input {...register("image")} placeholder="URL de l'image" color="blue" size="lg" label="Image" />
                    <p>{errors.image?.message}</p>

                    <Input {...register("date")} type="date" color="blue" size="lg" label="Date" />
                    <p>{errors.date?.message}</p>

                    <Input {...register("categorie")} type="select" options={categorie} color="blue" size="lg" label="Categorie" defaultValue="general" />
                    <p>{errors.categorie?.message}</p>

                    <Input {...register("title")} placeholder="Title" color="blue" size="lg" label="Title" />
                    <p>{errors.title?.message}</p>

                    <div>
                        <label htmlFor="user_id" className="block text-sm font-medium text-gray-700">Employé</label>
                        <select
                            {...register("user_id")} // Remplacement ici
                            id="user_id"
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                            defaultValue=""
                        >
                            <option value="" disabled>Choisissez une Team </option>
                            {employees.map(emp => (
                                <option key={emp.id} value={emp.id}>{emp.name}</option>
                            ))}
                        </select>
                        <p className="text-red-500">{errors.user_id?.message}</p>
                    </div>

                    {/* Champ Live */}
                    <Input
                        {...register("lives")}
                        type="select"
                        options={[
                            { value: true, label: "Oui" },
                            { value: false, label: "Non" },
                        ]}
                        color="blue"
                        size="lg"
                        label="Live"
                    />
                    <p>{errors.lives?.message}</p>

                    <button type="submit" onClick={handleSaveClick} className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"> Add Annonce </button>
                </form>
            </FormProvider>
        </div>
    );
};

export default AddAnnonce;
